import request from "@/utils/request";
// const ipBaseURL = process.env.VUE_APP_BASE_MARKETING_API;

const test = location.origin.indexOf("test-huanxinxiang.qiumo.net.cn") >= 0;
const ipBaseURL = test ? 'https://test-latefall-marketing.qiumo.net.cn/latefall-marketing' : "https://latefall-marketing.qiumo.net.cn/latefall-marketing"

// 短信验证码
export const sdmobileSMS = (data) =>
  request({
    url: "/sdmobile/send/sms",
    method: "post",
    ipBaseURL,
    data,
  });

export const sdmobileOrder = (data) =>
  request({
    url: "/sdmobile/order/commit",
    method: "post",
    ipBaseURL,
    data,
  });
