export default {
  data() {
    return {
      analytics_params: {
        $TenantCode: "ZJQM", //渠道编码-封装平台参数channelCode传值一致
        touchCode: "bjhyjtll", //产品小类编码（获取方法见上表）
        businessLevel: "lo0mEv18jW", //产品编码(countCode)（获取方法见上表）
        businessType:"WBQD",
      },
    };
  },
  mounted() {
    this.loadScript();
  },
  methods: {
    // 埋点-手机号转码
    encodedPhoneNumber() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) return "";
      //手机号（唯一）base64转码
      return btoa(this.form.phone);
    },
    // 引入JSSDK
    async loadScript() {
      // 定义全局配置对象
      window.xwCodeDefaults = {
        domain: "https://m.sd.10086.cn/sd_collector_service/",
        apiKey: "9FD8C6EE6FA972068BFBA9129D440457",
      };
      // 创建并插入外部脚本
      const script = document.createElement("script");
      script.src = "/pages/analytics.min.js"; // 替换为实际路径
      // script.src =scriptPath;
      script.async = true;
      script.onload = () => {
        console.log("Analytics script loaded.");
        this.initializeAnalytics({steps: "20"});
      };
      script.onerror = (error) => {
        console.error("Failed to load analytics script:", error);
      };
      document.head.appendChild(script);
    },
    /*steps
     * 进入页面：20
     * 立即办理：21
     * 输入验证码：23
     * 获取验证码：24
     * 确认办理：22
     * 办理成功：99
     * 办理失败：-99
     */
    // 埋点
    initializeAnalytics({ steps = "", errCode = "" }) {
      const params = {
        ...this.analytics_params,
        $biz_img: steps == "20" ? this.biz_img : undefined, //只有在步骤码 steps 为20时传输
        userMobile: this.encodedPhoneNumber(),
        errCode: steps == "-99" ? errCode : undefined,
        steps,
      };
      console.log(`当前步骤${steps}:`, params)
      window.analytics.track("click", params);
    },
    getCookie(name) {
      const cookieArray = document.cookie.split("; ");
      for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].split("=");
        if (cookie[0] === name) {
          return decodeURIComponent(cookie[1]);
        }
      }
      return null;
    },
    // 获取设备ID
    getCookieDeviceID() {
      const value = this.getCookie("ajs_anonymous_id");
      return JSON.parse(value);
    },
  },
};
