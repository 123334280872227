<template>
  <div class="content">
    <div class="form">
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入手机号码"
        @input="phoneChange"
      />
      <template>
        <van-field
          v-model="form.smsCode"
          type="number"
          center
          maxlength="4"
          autocomplete="one-time-code"
          placeholder="请输入验证码"
          class="input-code"
          @focus="handleFocus"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/lijikaitong.png"
        class="submit"
        @click="onSubmit"
      />
    </div>

    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/card1.png"
    />
    <div class="warp">
      <img
        class="bg"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/rulsBG1.png"
      />
      <img
        class="bg bg2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/rulsBG2.png"
      />
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/card2.png"
      />

      <div class="rule">
        <img
          class="title"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/ruleTitle.png"
        />
        <div class="rule-box">
          <p>
            1、权益超市铂金会员（家庭流量版）19.9元/月，订购成功立即生效，每月自动续费。
          </p>
          <p>
            2、每月15GB定向视频流量（流量范围:爱奇艺、优酷、腾讯视频、芒果TV、咪咕视频、哔哩哔哩、快手、头条抖音、西瓜视频）及家庭权益N选一
          </p>
          <p>
            3、业务仅限山东移动用户办理，MO套餐、无极变速套餐、数据卡、物联卡、随心卡、行业应用卡、信息机等特殊资费客户不可订购余额不足或已欠费均无法订购。
          </p>
          <p>
            4、该产品仅可同时订购一次，无法重复订购。如已退订可再次办理。产品6个月内仅允许办理一次。
          </p>
          <p>5、退订方式:可发送7816至10086退订，退订次账期失效。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sdmobileSMS, sdmobileOrder } from "@/api/shanDongYD.js";
import shandongYD from "@/mixin/shandongYD.js";
import { embedding } from "@/api/utils.js";

export default {
  mixins: [shandongYD],
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        phone: "",
        // 产品代码
        productCode: "LP1732001938000001",
        // 特征值
        channelAccountCode: "",
        // 短信验证码
        smsCode: "",
      },
      showTermsDialog: false,
      biz_img:
        "https://qiumo-1305062006.cos.ap-shanghai.myqcloud.com/landingpage/sdyd-upload.png",
    };
  },
  created() {
    const { from = "", phone = "", xwPointC = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      // 特征值
      channelAccountCode: from,
      phone: phone,
      // 办理地址
      handleUrl: href,
      // 设备ID
      deviceCode: this.getCookieDeviceID(),
      // 投放平台
      platformCode: xwPointC
    };
  },

  methods: {
    async phoneChange() {
      if (process.env.NODE_ENV !== "development") {
        let filterPhone = /^1[3456789]\d{9}$/;
        const { channelAccountCode, phone } = this.form;
        if (!filterPhone.test(phone)) return;

        await embedding({
          embeddingCode: "o.sdmobile.phone.data",
          clickNum: "1",
          form: channelAccountCode,
          phone: phone,
        });
      }
    },
    handleFocus() {
      this.initializeAnalytics({ steps: "23" });
    },
    getAuthCode() {
      if (this.yzm_loading) return;
      if (!this.form.phone)
        return this.$toast.fail({
          message: "请输入手机号码",
        });
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$toast.fail({
          message: "手机号码输入错误",
        });
      } else {
        this.yzm_loading = true;
        this.initializeAnalytics({ steps: "24" });
        try {
          const { phone, productCode, channelAccountCode } = this.form;
          sdmobileSMS({ phone, productCode, channelAccountCode })
            .then((res) => {
              this.yzm_loading = false;
              if (res.status != 200) {
                this.$toast(res.message || "验证码获取失败");
              } else {
                // 设置倒计时秒
                this.sendAuthCode = false;
                this.auth_time = 60;
                let auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              this.yzm_loading = false;
            });
        } finally {
          this.yzm_loading = false;
        }
      }
    },
    handleSubmit() {
      this.initializeAnalytics({ steps: "22" });
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      sdmobileOrder(this.form).then((res) => {
        this.$toast.clear();
        if (res.status != 200) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          this.initializeAnalytics({ steps: "-99", errCode: res.message });
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          this.initializeAnalytics({ steps: "99" });
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{4}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$toast.fail({
          message: "请输入手机号码",
        });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$toast.fail({
          message: "手机号码输入错误",
        });
      } else if (!this.form.smsCode) {
        this.$toast.fail({
          message: "请输入验证码",
        });
      } else if (!filtercode.test(this.form.smsCode)) {
        this.$toast.fail({
          message: "请输入4位验证码",
        });
      } else {
        this.initializeAnalytics({ steps: "21" });
        this.$dialog
          .confirm({
            title: "套餐说明",
            message: `业务名称：铂金会员家庭流量版
业务内容：每月5GB定向视频流量（流量范
围：爱奇艺、优酷、腾讯视频、芒果TV、咪
咕视频、哗哩哗哩、快手、头条抖音、西瓜
视频）及家庭权益N选一，还可领取1GB国
内通用流量
业务资费：19.9元/月
生效时间：立即生效
`,
            messageAlign: "left",
            confirmButtonText: "确认订购",
            cancelButtonText: "我再想想",
          })
          .then(() => {
            this.handleSubmit();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #eb3242
    url("https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/top03.png")
    center top/contain no-repeat;
  padding-top: 545px;

  .img {
    width: 100%;
  }

  .card {
    width: 711px;
    display: block;
    margin: 0 auto;
  }

  .form {
    position: relative;
    width: 711px;
    height: 485px;
    margin: 0 auto;
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/platinumMemberRightsSupermarket/inputBox.png")
      center center/contain no-repeat;
    padding-top: 50px;
    box-sizing: border-box;

    ::v-deep .van-cell {
      width: 614px;
      height: 91px;
      border-radius: 45px;
      border: solid 2px #ffd67f;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 51px;
      font-size: 36px;
      box-sizing: border-box;

      .van-field__control {
        font-weight: 400 !important;
      }

      &.input-code {
        margin-top: 20px;

        .code {
          width: 231px;
          background-image: linear-gradient(
              -90deg,
              #ffe05f 0%,
              #ffe997 50%,
              #fff1d0 100%
            ),
            linear-gradient(#ffffff, #ffffff);
          background-blend-mode: normal, normal;
          border-radius: 0px 45px 45px 0px;
          border: solid 1px #ffd67f;
          text-align: center;
          line-height: 88px;
          font-size: 30px;
          font-weight: normal;
          color: #c12b00;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 30px;
        color: #c12b00;
        &::-webkit-input-placeholder {
          font-size: 36px;
          color: #c12b00;
          opacity: 0.5;
        }
      }
    }

    .submit {
      width: 613px;
      display: block;
      margin: 40px auto 0;
    }
  }

  .warp {
    position: relative;

    .bg {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .bg2 {
      z-index: 1;
    }

    .card,
    .rule {
      position: relative;
    }

    .rule {
      z-index: 2;
      padding: 95px 29px 47px 26px;
      .title {
        display: block;
        margin: 0 auto;
        width: 184px;
      }

      &-box {
        padding-top: 25px;
        p {
          margin: 0;
          font-size: 24px;
          font-weight: normal;
          line-height: 36px;
          letter-spacing: 0px;
          color: #c12b00;
        }
      }
    }
  }
}
</style>
